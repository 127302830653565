
          @import '@/assets/scss/_variables.scss';
          @import '@/assets/scss/_mixins.scss';
        







// Allow element/type selectors, because this is global CSS.
// stylelint-disable selector-max-type

@import './assets/scss/base.scss';
@import './assets/fonts/_fonts.css';

#app {
  height: 100%;
  overflow: hidden;
  font-family: 'Brandon Grotesque', Helvetica, Arial, sans-serif;
  font-size: 16px;
  color: #fff;
  text-align: center;
  background: url('~@/assets/images/bg_desktop.png') no-repeat;
  background-attachment: fixed;
  background-size: cover;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  @media only screen and (min-width: 1100px) {
    overflow: auto;
  }
}
