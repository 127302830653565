
          @import '@/assets/scss/_variables.scss';
          @import '@/assets/scss/_mixins.scss';
        

























































.home {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
}
.logo-container {
  position: relative;
  display: flex;
  padding: 50px;
  @media only screen and (min-width: 1100px) {
    position: sticky;
    top: 0;
  }
}
.header {
  display: flex;
  flex-direction: column;
  padding: 0 30px 50px 30px;
  font-size: 20px;
  @media only screen and (min-width: 720px) {
    padding: 0 50px 50px 50px;
  }
  @media only screen and (min-width: 1100px) {
    padding: 0 200px 50px 200px;
  }
  @media only screen and (min-width: 1300px) {
    padding: 0 300px 50px 300px;
  }
  .title {
    font-family: 'Writable Story', Helvetica, Arial, sans-serif;
    font-size: 50px;
    @media only screen and (min-width: 800px) {
      font-size: 60px;
    }
  }
}
.content {
  display: flex;
  flex-wrap: wrap;
  padding: 0 30px;
  @media only screen and (min-width: 1100px) {
    padding: 0 150px;
  }
  @media only screen and (min-width: 1100px) {
    padding: 0 190px;
  }
  .card {
    display: flex;
    flex-basis: calc(50% - 30px);
    align-items: center;
    margin: 15px;
    cursor: pointer;
    border-radius: 25px;
    transition: all 0.2s linear;

    @media only screen and (min-width: 720px) {
      flex-basis: calc(33% - 30px);
    }
    @media only screen and (min-width: 1100px) {
      flex-basis: calc(25% - 30px);
    }

    .img-container {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      .background-image {
        position: absolute;
        top: 0;
        left: 0;
        display: block;
      }
      .parent-image {
        z-index: 2;
      }
      img {
        width: 100%;
      }
    }
    @media only screen and (min-width: 1100px) {
      &:hover {
        transform: translateY(-20px);
        .parent-image {
          z-index: 0;
        }
        .background-image {
          z-index: 1;
        }
      }
    }
  }
}
.subtitle {
  z-index: 1;
  margin-top: 10px;
  margin-bottom: 20px;
  font-family: 'Brandon Grotesque Medium', Helvetica, Arial, sans-serif;
  font-size: 16px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 1.6px;
  opacity: 0.5;
}
