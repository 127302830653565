// Allow element/type selectors, because this is global CSS.
// stylelint-disable selector-max-type
html {
  height: 100%;
}
body {
  height: 100%;
  margin: 0;
}
.logo {
  width: 105px;
  height: 56px;
}
.prev-icn {
  width: 12px;
  height: 24px;
  cursor: pointer;
}
.next-icn {
  width: 12px;
  height: 24px;
  cursor: pointer;
}
.disable {
  opacity: 0.5;
}
.modal {
  width: 100%;
  min-width: 500px;
  // height: 516px;
  // max-width: 980px;
  height: 100%;
  padding: 103px 200px;
  background-color: transparent !important;
  @media only screen and (max-width: 700px) {
    width: 300px;
    min-width: 300px;
  }
  @media only screen and (max-width: 400px) {
    width: 250px;
    min-width: 250px;
  }

  footer {
    align-self: center;
  }
}
footer {
  display: flex;
  justify-content: center;
  margin: 20px;
  a {
    color: white !important;
    text-decoration: none;
  }
  .bold {
    font-weight: bold;
  }
  .orange {
    color: #dc941e;
  }
}
