@font-face {
  font-family: 'Brandon Grotesque';
  font-style: normal;
  font-weight: bold;
  src: url('BrandonGrotesque-Bold.woff') format('woff');
}
@font-face {
  font-family: 'Brandon Grotesque';
  font-style: normal;
  font-weight: normal;
  src: url('BrandonGrotesque-Regular.woff') format('woff');
}
@font-face {
  font-family: 'Brandon Grotesque Medium';
  font-style: normal;
  font-weight: medium;
  src: url('BrandonGrotesque-Medium.woff') format('woff');
}
@font-face {
  font-family: 'Writable Story';
  font-style: normal;
  font-weight: normal;
  src: url('Writable-Story.otf') format('opentype');
}
